* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.stake_container {
  margin: 50px 150px 100px;
  background: aliceblue;
  border-radius: 15px;
  box-shadow: 5px 5px 11px 4px #dbdbdb;
  padding-bottom: 130px;
}

.stake_container h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.stake_container h6 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 16px;
}

.create_c {
  background: #dbeafe;
  margin: 25px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
}

.rp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-r,
.col-p {
  background-color: #ffffff;
  width: 525px;
  height: 220px;
  border-radius: 15px;
  /* margin-bottom: 45px; */
}

.col-p h4 {
  text-align: center;
  padding: 25px;
}

.col-r h4 {
  text-align: center;
  padding: 25px;
}

.ll {
  height: 50px;
  width: 2px;
  color: #dbeafe;
}

.col-r .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
}

.col-p .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
}

.card_sec {
  margin: 25px 70px;
  padding-bottom: 50px;
}

.card_sec .cols {
  float: left;
  width: 45%;
  height: 350px;
  background-color: #fff;
  border-radius: 15px;
  margin-left: 35px;
}

.img_cont {
  margin: 5px 75px;
  padding: 20px;
  text-align: center;
}

.img_cont img {
  padding: 10px;
  border-radius: 150px;
  background: #f3f0e2;
  box-shadow: inset 9px 9px 17px #d3d1c5, inset -9px -9px 17px #ffffff;
}

.col_descd {
  text-align: center;
  padding: 5px 100px;
}

.buttons_sec {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 100px;
}
.row {
  margin: 0 !important;
}

@media screen and (max-width: 1280px) {
  .col-r,
  .col-p {
    width: 450px;
  }

  .card_sec .cols {
   /* height: 320px;*/
    margin-left: 25px;
  }

  .img_cont {
    margin: 5px 60px;
  }
}

@media screen and (max-width: 1024px) {
  .col-r,
  .col-p {
    width: 325px;
  }

  .img_cont {
    margin: 5px 40px;
    padding: 20px 80px;
  }

  .ll {
    display: none;
  }

  .buttons_sec {
    padding: 0px;
  }

  .row {
    flex-wrap: nowrap !important;
  }
}

@media screen and (max-width: 768px) {
  .img_cont {
    margin: 0px;
    padding: 20px 80px;
  }

  .row {
    flex-wrap: nowrap !important;
  }

  .cc h4 {
    font-size: 20px;
  }

  .cc p {
    font-size: 12px;
  }

  .cc2 button {
    font-size: 12px;
  }

  .col-r h4 {
    font-size: 20px;
  }

  .col-p h4 {
    font-size: 20px;
  }

  .col-left p {
    font-size: 12px;
  }

  .col-right p {
    font-size: 12px !important;
  }

  .col-r,
  .col-p {
    width: 250px;
    margin-left: 10px;
  }

  .card_sec .cols {
    height: 250px;
    margin-left: 25px;
  }
}

@media screen and (max-width: 480px) {
  .stake_container {
    margin: 10px;
  }

  .stake_container h1 {
    font-size: 24px;
  }

  .stake_container h6 {
    font-size: 12px;
  }

  .col-p,
  .col-r {
    width: 170px;
    margin-left: 2px;
  }

  .col-p h4 {
    padding: 10px 5px;
  }

  .col-r h4 {
    padding: 10px 5px;
  }

  .card_sec {
    margin: 10px;
  }

  .card_sec .cols {
    margin: 5px 8px 5px 12px;
  }

  .cc p {
    font-size: 10px;
  }

  .cc2 button {
    font-size: 10px;
    margin-top: 20px;
  }

  .col_descd {
    font-size: 12px;
    padding: 0px;
  }

  .img_cont {
    text-align: center;
    padding: 10px 5px;
  }
}
