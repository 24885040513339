* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hd_container {
  margin: 75px 50px;
}

.rrow {
  display: flex;
}

.colld,
.colrd {
  flex: 50%;
}

.video-js {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  width: 620px;
  height: 420px;
}

.vjs-poster {
  border-radius: 10px;
}

.video-js .vjs-big-play-button {
  background-color: #3fa9f5;
}

.colld {
  padding: 25px;
}

.colld h1 {
  font-size: 60px;
}

.colld h5 {
  padding: 25px 0px;
  font-family: "Poppins", Sans-serif;
  font-weight: 200;
  line-height: 1.8em;
}

.colld button {
  margin-top: 30px;
  padding: 10px 35px;
  border: none;
  border-radius: 10px;
  background: rgb(110, 122, 255);
  background: linear-gradient(
    69deg,
    rgba(110, 122, 255, 1) 0%,
    rgba(144, 5, 5, 1) 100%
  );
  color: #fff;
  font-size: 20px;
  font-weight: 600px;
  letter-spacing: 1px;
}

/* .coll button:hover {
  border: 2px solid linear-gradient(to right, red, purple);
  background: aliceblue;
  color: #000;
} */

@media screen and (max-width: 1024px) {
  .rrow {
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width: 768px) {
  .rrow {
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width: 480px) {
  .hd_container {
    margin: 25px;
  }

  .colld {
    padding: 0px;
  }

  .colld h1 {
    font-size: 40px;
  }

  .colld h5 {
    padding: 5px 0px;
    font-size: 20px;
  }

  .colld button {
    margin: 5px 0px 25px;
  }

  .video-js {
    width: 320px;
    height: 220px;
    margin-bottom: 100px;
  }
}
