* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.d_container {
  margin: 50px 100px 200px;
  border-radius: 15px;
  background: aliceblue;
  box-shadow: 5px 5px 11px 4px #dbdbdb;
}

.d_container h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 30px;
}

.d_container .row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 50px;
  padding-left: 70px;
  padding-right: 70px;
}

.column {
  height: 200px;
  text-align: center;
  float: left;
  padding: 25px 150px 75px;
  background-color: #fff;
  border-radius: 10px;
}

.col {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
}

@media screen and (max-width: 1024px) {
  .column {
    padding: 25px 100px 75px;
  }

  .column {
    margin: 25px;
  }
}

@media screen and (max-width: 768px) {
  .d_container h1 {
    font-size: 34px;
  }

  .d_container {
    margin: 10px;
  }
}
